 <template>
  <div class="Index_map" ref="vlbox">
    <div id="map"></div>
    <aside>
      <div class="hide">
        <img src="../assets/imgs/btn_left.png" alt="" v-if="hide==1" @click="hide=0" >
        <img src="../assets/imgs/btn_right.png" alt="" v-if="hide==0" @click="hide=1">
      </div>
      <div class="aside_main" v-show="hide==1">
      <div class="title">
        <p>
          <img src="../assets/imgs/zzjg.png" alt="" />
          组织架构
        </p>
        <!-- <el-select v-model="form.value" size="mini" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
      </div>
      <div class="search">
        <el-input placeholder="项目名称" v-model="form.input2" size="mini">
          <template slot="append">
            <i class="el-icon-search" @click="search"></i>
          </template>
        </el-input>
      </div>

      <div class="company scrollbar_width0">
          <div  v-show="form.state==1">
              <div v-for="(item, index) in treeList" :key="item.name">
              <el-tooltip
                v-show="item.name.length >= 20"
                class="item"
                effect="light"
                :content="item.name"
                placement="top-start"
              >
                <p
                  class="project_item"
                  @click="projectPChange(item, index)"
                  :class="projectP == index ? 'active' : ''"
                >
                  <i class="el-icon-office-building"></i>
                  <span style="margin-left: 4px">{{ item.name }}</span>
                </p>
              </el-tooltip>
              <p
                class="project_item"
                v-show="item.name.length < 20"
                @click="projectPChange(item, index)"
                :class="projectP == index ? 'active' : ''"
              >
                <i class="el-icon-office-building"></i
                ><span style="margin-left: 4px">{{ item.name }}</span>
              </p>
            </div>
          </div>
          <el-tree
          v-show="form.state==0"
            :data="treeData"
            :props="defaultProps"
            @node-click="handleNodeClick"
          ></el-tree>
          
        </div>
      <div class="bottom">
        <div>
          <p>
            <span class="green_c"></span>
            项目总数
          </p>
          <p>{{ form.projectCount }}</p>
        </div>
        <div>
          <p>
            <span class="orange_c"></span>
            视频在线/全部
          </p>
          <p>{{ form.onlineCount }}</p>
        </div>
      </div>
      </div>
    </aside>
    <div class="info" v-show="info == 1" ref="info">
      <div class="info_left">
        <p>
          <img src="../assets/imgs/zzjg.png" alt="" />
          {{ pjForm.name }}
        </p>
        <div class="item">
          <p class="gray999">所属区域</p>
          <p class="gray666">{{ pjForm.areaName }}</p>
        </div>
        <div class="item">
          <p class="gray999">项目类型</p>
          <p class="gray666" v-show="pjForm.type">{{ pjForm.type==1?'房屋':'市政' }}</p>
        </div>
        <div class="item">
          <p class="gray999">工程造价</p>
          <p class="gray666">{{ pjForm.orgPrice }}</p>
        </div>
        <div class="item">
          <p class="gray999">建筑面积</p>
          <p class="gray666" v-show="pjForm.orgSize">{{ pjForm.orgSize }}万㎡</p>
        </div>
        <div class="item">
          <p class="gray999">开 竣 工</p>
          <p class="gray666">{{ timestampToTime1(pjForm.startTime) }}-{{ timestampToTime1(pjForm.endTime) }}</p>
        </div>
        <div class="item">
          <p class="gray999">项目经理</p>
          <p class="gray666">{{ pjForm.headName }}</p>
        </div>
        <div class="item">
          <p class="gray999">联系电话</p>
          <p class="gray666">{{ pjForm.phone }}</p>
        </div>
        <div class="item">
          <p class="gray999">项目地址</p>
          <p class="gray666">{{ pjForm.orgAddress }}</p>
        </div>
      </div>
      <div class="info_right">
        <p class="right_title">
          视频列表
          <img src="../assets/imgs/title-line.png" alt="" />
        </p>
        <el-table :data="tableData" max-height="332px" style="width:350px">
          <el-table-column
            prop="name"
            label="视频名称"
            :show-overflow-tooltip="true"
            width="110"
          >
          </el-table-column>
          <el-table-column
            prop="sn"
            label="编号"
            :show-overflow-tooltip="true"
            width="90"
          >
          </el-table-column>
          <el-table-column
            prop="address"
            width="80"
            :show-overflow-tooltip="true"
            label="状态"
          >
          <template slot-scope="scope">
                <!-- {{ scope.row.online==1?'在线':'离线' }} -->
                <el-button type="success" plain v-if="scope.row.online==1" size="mini">在线</el-button>
                <el-button type="danger" plain v-else-if="scope.row.online==0" size="mini">离线</el-button>
                <el-button type="warning" plain v-else size="mini">异常</el-button>
              </template>
          </el-table-column>
          <el-table-column
            prop="address"
            width="60"
            :show-overflow-tooltip="true"
            label="播放"
          >
          <template slot-scope="scope">
                <i class="el-icon-video-play" v-show="scope.row.online==1&&scope.row.hidden==1" @click="videoShow(scope.row.monitorNo,scope.row.orgName)"></i>
              </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
     <div class="modal" v-show="popup == 1" >
      <div class="popup" ref="vlmodal">
        <div class="title">
          <p class="blue">
            <i class="el-icon-info"></i>您可以点击
            <span class="weight">鼠标右键</span>
            开启云台控制，对视频画面进行缩小、放大、旋转操作。
            <span style="font-weight:600;margin-left:156px">{{orgName}}</span>
          </p>
          <span @click="close">
              <i class="el-icon-close"></i>
            </span>
        </div>
        <div class="hk">
          <div id="playWnd" class="playWnd"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import { timestampToTime1 } from "@/utils/getTime.js";
import { WebControl } from "../../public/static/web-control.esm.min";
import {
  getAll,getTotal,getProjectList,deviceGetList,doLoginByEmpCode
} from "@/api/apis.js";
export default {
  data() {
    return {
      map: null,
      options: [],
      form: {state:0,modalState:0},
      markerIcon: [],
      sign: require("@/assets/imgs/project-sign.png"),
      signed: require("@/assets/imgs/project-elsected.png"),
      zoom: 9,
      center: [104.07, 30.67],
      mapInfoWin: null,
      //当前播放视屏监控点号
      currentCIColde: [],
       cameraindexcode: "",
      //当前播放视屏序列
      currentSequence: 0,
      //定时器
      timeOut: 0,
      popup:0,
      //插件对象
      oWebControl: null,
      treeList:[],
      timestampToTime1,
      treeData: [],
      porjectList:[],
      defaultProps: {
        children: "children",
        label: "name",
      },
      orgName:'',
      hide:1,
      pjForm:{},
      info: 0,
      projectP:-1,
      tableData: [],
    };
  },
  methods: {
    RenderingPointer() {
      this.map.remove(this.markerIcon);
      this.markerIcon = [];
      this.porjectList.forEach((item) => {
        let arr=item.coordinates?item.coordinates.split(','):[104.07, 30.67]
      let markerIcon = new AMap.Marker({    
        position: new AMap.LngLat(arr[0],arr[1]),
        icon: this.sign,
      });
      this.markerIcon.push(markerIcon);
      var _this = this;
      markerIcon.on("mouseover", function () {
        _this.getDivice(item.id,1)       
      });
      markerIcon.on("mousemove", function (e) {
        _this.$refs.info.style.left=e.pixel.x+16+'px'
        _this.$refs.info.style.top=e.pixel.y+16+'px'     
      });
      markerIcon.on("dblclick", function () {
        _this.getDivice(item.id,2)       
      });
      });
      this.map.add(this.markerIcon);
    },
    getData() {
      let data = { id: this.currentId };
      getProjectList(data).then((res) => {
        if (res.code == 200) {
          this.porjectList=res.data
          this.RenderingPointer()
        }
      });
    },
    getDivice(id,num) {
      let data = { orgId:id };
      deviceGetList(data).then((res) => {
        if (res.code == 200) {         
          if(num==1){
              this.pjForm = res.data.sysOrg;
          this.tableData=res.data.list
          this.info = 1;
          }else if(num==2){
            let str=''
              res.data.list.forEach((item) => {
        str += item.monitorNo + ",";
      });
      this.videoShow(str.substr(0, str.length - 1));
          }
        }else{
          this.message.error(res.data.msg)
        }
      });
    },
    //获取视频状态
    getState(){
      let data = { orgId: this.currentId };
      getTotal(data).then((res) => {
      if (res.code == 200) {
        this.form.projectCount = res.data.projectCount;
        this.form.onlineCount = res.data.onlineCount;
        this.$forceUpdate()
      }
    });
    },
     search(){
      if(this.form.input2!=''){
        this.form.state=1
        let data={name:this.form.input2}
      getAll(data).then(res=>{
        if(res.code==200){
          this.treeList=res.data
        }
      })
      }else{
        this.form.state=0
        this.getdata()
      }    
    },
    handleNodeClick(data) {
      this.companyName = data.name;
      this.currentId = data.id;
      this.info = 0;
      this.getData();

    },
    projectPChange(v,i){
      this.projectP=i
        this.currentId = v.id;
        this.companyName = v.name;
      this.getData();

    },
     close() {
      this.popup = 0;
      // 标签关闭
      if (this.oWebControl != null) {
        this.oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
        this.oWebControl.JS_Disconnect().then(
          function () {},
          function () {}
        );
      }
    },
    //播放视频
    videoShow(cameraindexcode,orgName) {
      this.orgName=orgName
      this.currentCIColde = cameraindexcode.split(",");
      this.currentSequence = 0;
      this.popup = 1;
      this.$nextTick(() => {
        this.$refs.vlmodal.style.height=this.$refs.vlbox.offsetHeight+'px'
        this.$refs.vlmodal.style.width=(this.$refs.vlbox.offsetHeight-50)*1.78+'px'
        });
      
      this.initPlugin();
    },
        //初始化
    init() {
      var _this = this;
      _this.getPubKey(function () {
        var appkey = "26177308";
        var secret = "3u7SqzJ73wmfHnMdRKnW";
        var ip = "118.123.244.181";
        var szPort = "443";
        var snapDir = "D:SnapDir";
        var videoDir = "D:VideoDir";
        var layout = _this.currentlayout;
        // var encryptedFields = ["secret"];
        var szShowToolbar = "1";
        var szShowSmart = "1";
        var btIds = "0,16,256,257,258,259,260,512,513,514,515,516,517,768,769";

        appkey = appkey.replace(/(^\s*)/g, "");
        appkey = appkey.replace(/(\s*$)/g, "");

        secret = secret.replace(/(^\s*)/g, "");
        secret = secret.replace(/(\s*$)/g, "");

        ip = ip.replace(/(^\s*)/g, "");
        ip = ip.replace(/(\s*$)/g, "");

        szPort = szPort.replace(/(^\s*)/g, "");
        szPort = szPort.replace(/(\s*$)/g, "");

        snapDir = snapDir.replace(/(^\s*)/g, "");
        snapDir = snapDir.replace(/(\s*$)/g, "");

        videoDir = videoDir.replace(/(^\s*)/g, "");
        videoDir = videoDir.replace(/(\s*$)/g, "");

        var port = parseInt(szPort);
        var showSmart = parseInt(szShowSmart);
        var showToolbar = parseInt(szShowToolbar);
        var enableHttps = 1;

        // encryptedFields = "secret";
        console.log({
          appkey: appkey,
          secret: secret,
          ip: ip,
          playMode: 0, // 预览
          port: port,
          snapDir: snapDir,
          videoDir: videoDir,
          layout: layout,
          enableHTTPS: enableHttps,
          showToolbar: showToolbar,
          showSmart: showSmart,
          buttonIDs: btIds,
          // encryptedFields: encryptedFields,
        });
        _this.oWebControl
          .JS_RequestInterface({
            funcName: "init",
            argument: JSON.stringify({
              appkey: appkey,
              secret: secret,
              ip: ip,
              playMode: 0, // 预览
              port: port,
              snapDir: snapDir,
              videoDir: videoDir,
              layout: layout,
              enableHTTPS: enableHttps,
              showToolbar: showToolbar,
              showSmart: showSmart,
              buttonIDs: btIds,
              // encryptedFields: encryptedFields,
            }),
          })
          .then(function (oData) {
            _this.oWebControl.JS_Resize((_this.$refs.vlbox.offsetHeight-50)*1.78, _this.$refs.vlbox.offsetHeight-50); // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
            _this.startPreview();
            console.log("初始化", oData);
          });
      });
    },
    // 获取公钥
    getPubKey(callback) {
      var _this = this;
      _this.oWebControl
        .JS_RequestInterface({
          funcName: "getRSAPubKey",
          argument: JSON.stringify({
            keyLength: 1024,
          }),
        })
        .then(function (oData) {
          if (oData.responseMsg.data) {
            _this.pubKey = oData.responseMsg.data;
            callback();
          }
        });
    },
    //视屏预览
    startPreview() {
      var _this = this;
      //监控点编号
      // var cameraIndexCode = "185dad0787e94fefad7dc8df57da33ba";
      var cameraIndexCode = _this.currentCIColde[this.currentSequence];
      var streamMode = 0;
      var transMode = 1;
      var gpuMode = 0;
      var wndId = -1; //默认为空闲窗口回放
      cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, "");
      cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, "");
      this.oWebControl
        .JS_RequestInterface({
          funcName: "startPreview",
          argument: JSON.stringify({
            cameraIndexCode: cameraIndexCode,
            streamMode: streamMode,
            transMode: transMode,
            gpuMode: gpuMode,
            wndId: wndId,
          }),
        })
        .then(function (oData) {
          _this.currentSequence++;
          console.log(oData);
          if (_this.currentSequence < _this.currentCIColde.length) {
            _this.timeOut = setTimeout(function () {
              _this.startPreview();
            }, 1000);
          } else if (_this.currentSequence == 25) {
            _this.$message("视屏最多支持播放24条");
            clearTimeout(_this.timeOut);
          } else {
            clearTimeout(_this.timeOut);
          }
        });
    },
    //回调函数
    cbIntegrationCallBack(oData) {
      var msg = JSON.stringify(oData.responseMsg.msg);
      msg = msg.substring(1, msg.length - 1);
      var msgArray = msg.split(",");
      var cameraIndex = msgArray[0];
      if (msgArray[0].length > 25) {
        var array = cameraIndex.split(":");
        this.currentCIColdeCJ = array[1].substring(2, array[1].length - 2);
      }
      // showCBInfo(JSON.stringify(oData.responseMsg));
    },
    // 初始化插件
    initPlugin() {
      var _this = this;
      var oWebControl = new WebControl({
        szPluginContainer: "playWnd",
        iServicePortStart: 15900,
        iServicePortEnd: 15909,
        szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11", // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: function () {
          _this.oWebControl.JS_SetWindowControlCallback({
            cbIntegrationCallBack: _this.cbIntegrationCallBack,
          });
          oWebControl
            .JS_StartService("window", {
              dllPath: "./VideoPluginConnect.dll",
            })
            .then(function () {
              oWebControl.JS_CreateWnd("playWnd", 1184, 665).then(function () {
                _this.init();
              });
            });
        },
        cbConnectError: function () {
          console.log("cbConnectError");
          _this.oWebControl = null;
          _this.$message("插件未启动，正在尝试启动，请稍候...");
          WebControl.JS_WakeUp("VideoWebPlugin://");
          let initCount = 0;
          initCount++;
          if (initCount < 3) {
            setTimeout(function () {
              _this.initPlugin();
            }, 3000);
          } else {
            _this.$message("插件启动失败，请检查插件是否安装！");
          }
        },
        cbConnectClose: function (bNormalClose) {
          // 异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          console.log(bNormalClose);
          _this.oWebControl = null;
        },
      });
      this.oWebControl = oWebControl;
    },
  },
  created(){
     let str = window.location.href;
    // let i = str.indexOf("=");
    // let code = str.substr(i + 1);
    let i = str.split("=");
    let code = i[1];    
    if (code!=undefined) {
         doLoginByEmpCode({ empCode: code }).then(res => {
        if (res.code == 200) {
         getAll().then((res) => {
      if (res.code == 200) {
        this.treeData = res.data;
        this.treedata = res.data;
        this.currentId = res.data[0].id;
        this.getData()
        this.getState()
      } else {
        this.treeData = [];
      }
    });

        } else {
          this.$message.error(res.data.msg);
        }
      });
    }else {     
      console.log('mimadenglu111');
      
      let u1 = localStorage.getItem("p");
      if (u1 == null) {
        this.$router.push("/");
        
      }
         getAll().then((res) => {
      if (res.code == 200) {
        this.treeData = res.data;
        this.treedata = res.data;
        this.currentId = res.data[0].id;
        this.getData()
        this.getState()
      } else {
        this.treeData = [];
      }
    });
    }

  },
  mounted() {
    var _this = this;
    //实例地图对象
    var map = new AMap.Map("map", {
      zoom: this.zoom, //级别
      center: this.center, //中心点坐标
      // mapStyle: "amap://styles/d3433910ef48669918e31f34b4d15a73",
      // lang:'en'
    });
    this.map = map;
    map.on("click", function () {
      _this.info = 0;
    });
    this.RenderingPointer();
  },
    destroyed() {
    // 标签关闭
    if (this.oWebControl != null) {
      this.oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
      this.oWebControl.JS_Disconnect().then();
    }
    clearTimeout(this.timeOut);
  },
  computed: {
    currentlayout: function () {
      switch (this.currentCIColde.length) {
        case 1:
          return "1x1";
        case 2:
          return "1x2";
        case 3:
          return "1+2";
        case 4:
          return "2x2";
        case 5:
          return "1+5";
        case 6:
          return "1+5";
        case 7:
          return "1+7";
        case 8:
          return "1+7";
        case 9:
          return "3x3";
        case 10:
          return "1+9";
        case 11:
          return "4+9";
        case 12:
          return "4+9";
        case 13:
          return "4+9";
        case 14:
          return "4x4";
        case 15:
          return "4x4";
        case 16:
          return "4x4";
        case 17:
          return "4x6";
        case 18:
          return "4x6";
        case 19:
          return "4x6";
        case 20:
          return "4x6";
        case 21:
          return "4x6";
        case 22:
          return "4x6";
        case 23:
          return "4x6";
        case 24:
          return "4x6";
        default:
          return "4x6";
      }
    },
  },
};
</script>

<style lang="less" >
.Index_map {
  width: 100%;
  height: 100%;
  position: relative;
  #map {
    width: 100%;
    height: 100%;
  }
  aside {
    position: absolute;
    top: 2%;
    left: 10px; 
    height: 96%;
    background-color: #fff;
    .aside_main{
      width: 320px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    display: flex;
    flex-direction: column;
    .title {
      line-height: 40px;
      margin-top: 10px;
      width: 320px;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      img {
        margin-right: 6px;
      }
      .el-select {
        .el-input {
          width: 100px;
        }
        .el-input__inner {
          border: none;
        }
      }
    }
    .search {
      padding-left: 10px;
      .el-input-group__append {
        padding: 0 6px;
        background-color: #e7eaef;
        border: none;
        cursor: pointer;
      }
      .el-input {
        position: relative;
        top: 14px;
        width: 300px;
        .el-input__inner {
          background-color: #e7eaef;
          border: none;
        }
      }
    }
    .company {
      flex: 1;
      box-sizing: border-box;
      margin-top: 30px;
      .project_item {
          border-bottom: 1px dashed #ebf0f5;
          font-size: 12px;
          padding-left: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        .active {
          color: #466cd4;
        }
    }
    .bottom {
      display: flex;
      justify-content: space-around;
      color: #595959;
      font-size: 14px;
      padding-bottom: 18px;
      p {
        line-height: 24px;
        text-align: center;
      }
      .green_c {
        border-radius: 50%;
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 2px solid #24bd49;
        margin-right: 4px;
        position: relative;
        top: 2px;
      }
      .orange_c {
        border-radius: 50%;
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 2px solid #f08207;
        margin-right: 4px;
        position: relative;
        top: 2px;
      }
    }
    .hide{
      position: absolute;
      top:45%;
      right:-26px;
      cursor: pointer;
    }
  }
  .info {
    position: absolute;
    width: 580px;
    height: 380px;
    background-color: #fff;
    display: flex;
    font-size: 12px;
    border-radius: 8px;
    .info_left {
      width: 220px;
      height: 380px;
      box-sizing: border-box;
      background-color: #f5f5f5;
      border-radius: 8px 0px 0px 8px;
      padding-top: 30px;
      padding-left: 18px;
      & > p:nth-child(1) {
        font-size: 14px;
        margin-bottom: 20px;
        img {
          margin-right: 6px;
        }
      }
      .item {
        display: flex;
        line-height: 30px;
        & > p:nth-child(1) {
          width: 60px;
        }
        & > p:nth-child(2) {
          width: 140px;
        }
      }
    }
    .info_right {
      width: 360px;
      height: 380px;
      box-sizing: border-box;
      border-radius: 0 8px 8px 0;
      padding-left: 20px;
      .right_title {
        line-height: 44px;
        font-size: 14px;
        img {
          margin-left: 6px;
          position: relative;
          top: -4px;
        }
      }
      .el-table {
        th {
          background-color: #fff;
          padding: 0px;
        }
        .cell {
          line-height: 30px;
        }
        i{
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    z-index: 1000;
    box-sizing: border-box;
    .popup {
      background-color: #fff;
      width: 1184px;
      height: 720px;
      box-sizing: border-box;
      margin: auto;
      .playWnd {
        width: 1184px;
        height: 665px;
        // border: 1px solid red;
      }
      .hk {
        width: 1184px;
        height: 665px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        background-color: #fff;
        border-bottom: 1px solid #1e3142;
        .blue {
          line-height: 50px;
          margin-left: 16px;
          font-size: 14px;
          .weight {
            font-weight: 600;
          }
          i {
            font-size: 22px;
            color: #ff6400;
            margin-right: 10px;
            position: relative;
            top: 3px;
          }
        }
        .btns {
          margin-right: 20px;
          .btn_r {
            width: 100px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #fe0000;
            text-align: center;
            margin-right: 18px;
            cursor: pointer;
          }
          .btn_g {
            width: 100px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #16bb9b;
            text-align: center;
            margin-right: 22px;
            cursor: pointer;
          }
          .btn_gray {
            width: 100px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #3d3d3d;
            text-align: center;
            margin-right: 18px;
            cursor: pointer;
          }
          .btn_b {
            width: 100px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #0090ff;
            text-align: center;
            margin-right: 18px;
            cursor: pointer;
          }
        }
        .el-icon-close {
          cursor: pointer;
          font-size: 18px;
          margin-right: 22px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>